import React, { Component } from "react";
import "./Sidenavbar.css";
import { withStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import dashboard from "../images/transparent_icons/dashboard.svg";
import delivery from "../images/transparent_icons/delivery.svg";
import pin from "../images/transparent_icons/pin.svg";
import comment from "../images/transparent_icons/comment.svg";

const useStyles = (theme) => ({
  text: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
      color: "red",
    },
  },
  img: {
    width: "20px",
    height: "30px",
    fontColor: "white",
    [theme.breakpoints.down("sm")]: {
      width: "40px",
      height: "40px",
      marginLeft: "0.9rem",
      marginRight: "1.5rem",
      fontColor: "pink",
    },
  },
});
class Sidenavbar extends Component {
  constructor() {
    super();
    this.state = {
      accounts1: {},
      comment1: {},
      employee1: {},
      jobsite1: {},
      order1: {},
      quote1: {},
      position1: "",
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        className="sidenav "
        style={{
          position: "fixed",
          height: "100%",
          width: "15%",
          top: "9%",
        }}
      >
        <NavLink className="main-nav" activeClassName="main-nav-active" to="/">
          <span
            style={{
              width: "20px",
              height: "30px",
              fontColor: "white",
              fontSize: "16px",
            }}
          >
            <img className={classes.img} src={dashboard} alt="dashboard" />{" "}
            <span
              style={{ fontColor: "white", fontSize: "16px" }}
              className={classes.text}
            >
              Dashboard
            </span>
          </span>
        </NavLink>
        <NavLink
          className="main-nav"
          activeClassName="main-nav-active"
          to="/viewtruck"
        >
          <span
            style={{
              fontColor: "white",
              fontSize: "18px",
            }}
          >
            <img src={pin} alt="search" className={classes.img} />{" "}
            <span
              style={{ fontColor: "white", fontSize: "16px" }}
              className={classes.text}
            >
              Truck
            </span>
          </span>
        </NavLink>

        <NavLink
          className="main-nav"
          activeClassName="main-nav-active"
          to="/viewdriver"
        >
          <span
            style={{
              fontColor: "white",
              fontSize: "18px",
            }}
          >
            <img src={delivery} className={classes.img} alt="Driver" />{" "}
            <span
              style={{ fontColor: "white", fontSize: "16px" }}
              className={classes.text}
            >
              Driver
            </span>
          </span>
        </NavLink>
        <NavLink
          className="main-nav"
          activeClassName="main-nav-active"
          to="/truck_Availability"
        >
          <span
            style={{
              fontColor: "white",
              fontSize: "18px",
            }}
          >
            <img src={delivery} className={classes.img} alt="truck" />{" "}
            <span
              style={{ fontColor: "white", fontSize: "16px" }}
              className={classes.text}
            >
              Truck Availability
            </span>
          </span>
        </NavLink>

        <NavLink
          className="main-nav"
          activeClassName="main-nav-active"
          to="/dispatcher"
        >
          <span
            style={{
              fontColor: "white",
              fontSize: "18px",
            }}
          >
            <img src={pin} alt="search" className={classes.img} />{" "}
            <span
              style={{ fontColor: "white", fontSize: "16px" }}
              className={classes.text}
            >
              Dispatch
            </span>
          </span>
        </NavLink>
        {/* <NavLink
          className="main-nav"
          activeClassName="main-nav-active"
          to="/viewcomplain"
        >
          <span
            style={{
              fontColor: "white",
              fontSize: "18px",
            }}
          >
            <img src={comment} alt="comment" className={classes.img} />{" "}
            <span
              style={{ fontColor: "white", fontSize: "16px" }}
              className={classes.text}
            >
              Comments
            </span>
          </span>
        </NavLink> */}
      </div>
    );
  }
}

export default withStyles(useStyles)(Sidenavbar);
