import * as React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import moment from "moment";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

function SimpleDialog(props) {
  const {
    open,
    onClose,
    pickup_Date,
    color_name,
    size,
    qty,
    job_name,
    PO_NO,
    cross_streets,
  } = props;
  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open} width={50}>
      <Paper
        sx={{
          background: "#554D4D",
          borderadRius: "30px",
          width: "100%",
        }}
      >
        <DialogTitle sx={{ background: "#554D4D", color: "#fff" }}>
          Order Details
        </DialogTitle>
        <List sx={{ pt: 0 }}>
          <ListItem
            autoFocus
            sx={{ backgroundColor: "white", width: "100%", mb: 2 }}
          >
            <Box>
              <div>
                {/* <p>
                  <b style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    PO # :
                  </b>{" "}
                  {PO_NO ? PO_NO : "0"}
                </p> */}
                <p>
                  <b style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    Pickup Date :
                  </b>{" "}
                  {moment(pickup_Date).format("ll")}
                </p>
                <p>
                  <b style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    Color :
                  </b>{" "}
                  {color_name}
                </p>
                <p>
                  {" "}
                  <b style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    Size :
                  </b>{" "}
                  {size}
                </p>
                <p>
                  {" "}
                  <b style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    Qty :
                  </b>{" "}
                  {qty}
                </p>
                <p>
                  <b style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    Job Name :
                  </b>{" "}
                  {job_name ? job_name : "N/A"}
                </p>
                <p>
                  <b style={{ fontWeight: "bolder", fontSize: "13px" }}>
                    Cross Streets :
                  </b>
                  {cross_streets}
                </p>
              </div>
              <ListItemText sx={{ backgroundColor: "#554D4D" }} primary="" />
            </Box>
          </ListItem>
        </List>
      </Paper>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo(props) {
  const {
    order,
    pickup_Date,
    color_name,
    size,
    qty,
    job_name,
    PO_No,
    cross_streets,
  } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (evt) => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <div>
      <VisibilityOutlinedIcon
        style={{ color: "#0075FF" }}
        onClick={handleClickOpen}
      />

      <SimpleDialog
        open={open}
        order={order}
        onClose={handleClose}
        job_name={job_name}
        pickup_Date={pickup_Date}
        PO_NO={PO_No}
        cross_streets={cross_streets}
        qty={qty}
        color_name={color_name}
        size={size}
      />
    </div>
  );
}
