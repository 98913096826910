import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import Card from "@material-ui/core/Card";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { API_URL1 } from "../services/url";
import Button from "react-bootstrap/Button";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
console.log(process.env.REACT_APP_MAPS_API_KEY);
const ProsMap = (props) => {
  const { sub_job_truck_id } = useParams();
  const [center, setCenter] = useState({ lat: 32.7458, lng: -111.6859 });
  const [zoom, setZoom] = useState(10);
  const [details, setDetails] = useState({
    color: "N/A",
    size: "N/A",
    qty: "N/A",
    true_qty: "N/A",
    truck: "N/A",
    driver: "N/A",
    customer_name: "N/A",
    customer_site_name: "N/A",
    truck_name: "N/A",
    src: { lat: "", lng: "" },
    destination: { lat: "", lng: "" },
    last_location: { lat: "", lng: "" },
    distance: "N/A",
    item_name: "N/A"
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {

        var newApiResponse = await axios.get(`${API_URL1}trucker/dispatch/dispatched/status?sub_job_truck_id=${sub_job_truck_id}`, {
          headers: {
            "x-access-token": localStorage.getItem("TruckertokenKey"),
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        });

        var src;
        var destination;

        var truck_details = newApiResponse.data.data;

        try {
          src = truck_details.quarry_geofence ? JSON.parse(truck_details.quarry_geofence) : { lat: "0", lng: "0" };
        } catch (error) {
          console.log(error);
          src = { lat: "0", lng: "0" };
        }

        try {
          destination = truck_details.customer_geofence ? JSON.parse(truck_details.customer_geofence) : { lat: "", lng: "" };
        } catch (error) {
          console.log(error);
          destination = { lat: "", lng: "" };
        }

        // If src's or destination's lat or lng is not integer, then convert it to integer
        src.lat = parseFloat(src.lat);
        src.lng = parseFloat(src.lng);
        destination.lat = parseFloat(destination.lat);
        destination.lng = parseFloat(destination.lng);

        console.log(src, destination);

        setDetails((prevDetails) => ({
          ...prevDetails,
          color: truck_details.color_name || "N/A",
          size: truck_details.size || "N/A",
          qty: truck_details.quantity || "N/A",
          true_qty: truck_details.true_quantity || "N/A",
          truck: truck_details.registration_number || "N/A",
          driver: truck_details.driver_name || "N/A",
          customer_name: truck_details.customer_name || "N/A",
          customer_site_name: truck_details.customer_site_name || "N/A",
          truck_name: truck_details.company_name || "N/A",
          src: src,
          destination: destination,
          item_name: truck_details.item_name || "N/A"
        }));

        console.log(newApiResponse);

        // const response = await axios.get(`${API_URL1}trucker/dispatch/dispatched`, {
        //   headers: {
        //     "x-access-token": localStorage.getItem("TruckertokenKey"),
        //     "Content-Type": "application/json",
        //     "Access-Control-Allow-Origin": "*",
        //   },
        // });

        // const data = response.data.data;
        // const truckId = Number(sub_job_truck_id);

        // const truckDetails = data.find((item) => item.sub_job_truck_id === truckId);
        // if (truckDetails) {
        //   const src = truckDetails.quarry_geofence ? JSON.parse(truckDetails.quarry_geofence) : { lat: "", lng: "" };
        //   const destination = truckDetails.customer_geofence ? JSON.parse(truckDetails.customer_geofence) : { lat: "", lng: "" };

        //   setDetails((prevDetails) => ({
        //     ...prevDetails,
        //     color: truckDetails.color_name || "N/A",
        //     size: truckDetails.size || "N/A",
        //     qty: truckDetails.processing_quantity || "N/A",
        //     truck: truckDetails.registration_number || "N/A",
        //     driver: truckDetails.driver_name || "N/A",
        //     customer_name: truckDetails.customer_name || "N/A",
        //     customer_site_name: truckDetails.customer_site_name || "N/A",
        //     truck_name: truckDetails.company_name || "N/A",
        //     src: src,
        //     destination: destination
        //   }));
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        try {
          const loc_response = await axios.get(
            `${API_URL1}appAdmin/map/findDriverLocation/${sub_job_truck_id}`
          );

          if (loc_response.data.success) {
            const last_location = loc_response.data.order.last_location ? JSON.parse(loc_response.data.order.last_location) : { lat: "", lng: "" };
            const distance = loc_response.data.order.distance || "N/A";

            setDetails((prevDetails) => ({
              ...prevDetails,
              last_location: last_location,
              distance: distance,
            }));
          }
        } catch (error) {
          console.error("Error fetching location data:", error);
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [sub_job_truck_id]);

  const handleApiLoaded = (map, maps) => {
    const { src, destination, last_location } = details;

    const iconMarker = new maps.MarkerImage(
      "https://img.icons8.com/emoji/344/delivery-truck.png",
      null,
      null,
      null,
      new maps.Size(32, 32)
    );

    if (last_location.lat && last_location.lng) {
      new maps.Marker({
        position: last_location,
        map: map,
        icon: iconMarker,
      });
    }

    const directionsService = new maps.DirectionsService();
    const directionsRenderer = new maps.DirectionsRenderer();
    directionsRenderer.setMap(map);

    console.log("src", src);
    console.log("destination", destination);

    directionsService.route(
      {
        origin: src,
        destination: destination,
        travelMode: maps.TravelMode.DRIVING,
      },
      (response, status) => {
        console.log("response", response, status);
        if (status === "OK") {
          directionsRenderer.setDirections(response);
        } else {
          console.log("Directions request failed due to " + status);
        }
      }
    );
  };

  const { color, size, qty, true_qty, truck, driver, customer_name, customer_site_name, truck_name, src, item_name } = details;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <TableContainer
      component={Paper}
      style={{
        marginTop: "1.5%",
        marginLeft: "10%",
        boxShadow: "5px 5px 6px #434344",
        backgroundColor: "rgba(28, 139, 133, 0.15)",
        borderRadius: "10px",
      }}
      sx={{ width: "100%", height: "84vh" }}
      align="left"
    >
      <div className="container row justify-space-around" style={{ width: "100%" }}>
        <div className="col-3">
          <Link to="/dispatched_order">
            <Button size="small" style={{ backgroundColor: "#2A707D", marginLeft: "68px" }} className="mt-2 mb-2">
              Back
            </Button>
          </Link>
          <Card style={{ backgroundColor: "#f0f0f0", boxShadow: "5px 5px 8px #888888", marginBottom: "3px" }}>
            <div className="card-body">
              <h5 className="card-title" style={{ fontSize: "11px", fontWeight: "bold" }}>
                Customer Name : {customer_name}
              </h5>
              <h6 className="card-subtitle" style={{ fontWeight: "bold" }}>Order Detail :</h6>
              <p>
                <strong>Customer Site : {customer_site_name}</strong><br />
                {/* <strong>Trucker : {truck_name}</strong><br /> */}
                <strong>Item : {item_name}</strong><br />
                <strong>Loaded Quantity : {true_qty !== "N/A" ? parseFloat(true_qty).toFixed(2) : "N/A"}</strong><br />
                <strong>Quantity : {qty !== "N/A" ? parseFloat(qty).toFixed(2) : "N/A"}</strong><br />
                <strong>Truck # : {truck}</strong><br />
                <strong>Driver Name : {driver}</strong>
              </p>
            </div>
          </Card>
        </div>
        <div style={{ height: "80vh", width: "70%", marginLeft: "30px" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API_KEY }}
            defaultCenter={center}
            defaultZoom={zoom}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            yesIWantToUseGoogleMapApiInternals
          ></GoogleMapReact>
        </div>
      </div>
    </TableContainer>
  );
};

export default ProsMap;
