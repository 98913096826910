import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "react-bootstrap/Button";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import LinearProgress from "@material-ui/core/LinearProgress";
import { API_URL1 } from "../services/url";
import axios from "axios";
import { useState, useEffect } from "react";
import DeleteTruck from "./DeleteTruck";
import { useNavigate } from 'react-router-dom';
import { MdEdit } from "react-icons/md";

const ViewTruck = () => {
  const [Tdata, setTdata] = useState([]);
  const navigate = useNavigate();
  const [record, setRecord] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(100);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setrowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    const FetchData = async () => {
      try {
        const adminId = localStorage.getItem("adminid");
        const truckerId = localStorage.getItem("trucker_id");
        const response = await axios.get(`${API_URL1}trucker/truck?truck_company_id=${adminId}&trucker_id=${truckerId}`, {
          headers: {
            "x-access-token": localStorage.getItem("TruckertokenKey"),
            "content-type": "application/json",
          },
        });
        if (response.data.trucks) {
          setTdata(response.data.trucks);
          setRecord(true);
        } else {
          setTdata([]);
          setRecord(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    FetchData();
  }, []);

  return (
    <Fragment>
      {!record ? <LinearProgress /> : ""}
      <Grid
        container
        style={{ marginBottom: "2%", marginTop: "2%", marginLeft: "1%" }}
      >
        <Grid item xs={2} sm={2}>
          <Link to="#">
            <Button
              style={{
                float: "left",
                // marginTop: "3%",
                marginLeft: "1.5rem",
                width: "132px",
                color: "white",
                backgroundColor: "#114C65",
                borderRadius: "25px",
              }}
            >
              {" "}
              Trucks
            </Button>
          </Link>
        </Grid>
        {/* <Grid item xs={8} sm={4}></Grid> */}
        <Grid item xs={8} sm={4}>
          <Link to="/addTruck">
            <Button
              style={{
                float: "left",
                // marginTop: "3%",
                // marginLeft: "24.5rem",
                width: "132px",
                color: "white",
                backgroundColor: "#000",
                borderRadius: "25px",
              }}
            >
              {" "}
              Add Truck
            </Button>
          </Link>
        </Grid>
      </Grid>
      {/*............................................................................................................................................. TRUCK TABLE */}
      {!record && Tdata.length === 0 ? (
        <div style={{ marginLeft: "4%" }}>Loading.........</div>
      ) : Tdata.length === 0 && record ? (
        <div style={{ marginLeft: "4%" }}>No Record Available</div>
      ) : (
        <Paper style={{ width: "110%", marginLeft: "3%" }}>
          <TableContainer
            sx={{
              background: "#F3F3F3",
              boxShadow: "5px 5px 6px #434344",
              borderRadius: "10px",
            }}
            style={{ height: "60vh" }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead style={{ backgroundColor: "#000000" }}>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: "#000000", color: "white" }}
                  >
                    Truck Number
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#000000",
                      color: "white",
                    }}
                    align="center"
                  >
                    Make
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                    align="center"
                  >
                    Model Number
                  </TableCell>
                  {/* <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                    align="center"
                  >
                    Truck Number
                  </TableCell> */}
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                    align="center"
                  >
                    Color
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                    align="center"
                  >
                    Truck/Traller type
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                    align="center"
                  >
                    Max Tons Hauled
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "#000000", color: "white" }}
                    align="center"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody style={{ backgroundColor: "#E4E4E4" }}>
                {Tdata &&
                  Tdata.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((item, key) => (
                    <TableRow
                      key={key}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">
                        {item.registration_number}
                      </TableCell>
                      <TableCell align="center"> {item.brand_model}</TableCell>
                      <TableCell align="center">{item.vehicle_no}</TableCell>
                      {/* <TableCell align="center">{!item?.truck_number ? 'N/A' :item?.truck_number }</TableCell> */}
                      <TableCell align="center">{item.color}</TableCell>
                      <TableCell align="center">{item.type}</TableCell>{" "}
                      <TableCell align="center">
                        {item.capacity
                          ? parseFloat(item.capacity).toFixed(2)
                          : "N/A"}
                      </TableCell>{" "}
                      <TableCell align="center">
                        {/* <Link
                          to={{
                            pathname: "/edittruck",
                            state: { edittruckdata: 46674 },
                          }}
                        >
                          
                        </Link> */}
                        <MdEdit
                          style={{ color: "#0075ff", fontSize: "17px" }}
                          sx={{ ml: -2.5 }}
                          variant="none"
                          onClick={() =>
                            navigate("/edittruck", {
                              state: { edittruckdata: item.truck_id },
                            })
                          }
                        >
                          <ModeEditOutlineOutlinedIcon sx={{ color: "#0075FF" }} />
                        </MdEdit>
                        <DeleteTruck data={item.truck_id} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={Tdata && Tdata.length}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            style={{ fontSize: 16 }}
          />
        </Paper>
      )}
    </Fragment>
  );
};

export default ViewTruck;
